<template>
	<div class="content">
		<div class="container">
			<div class="mb120-40">
				<p class="color-gray mb80-40 animationBlockFadeUp fs20-2">Создаем digital-проекты для лидеров рынка и тех, кто хочет ими стать.</p>
				<Percents :content="percents" v-if="percents"></Percents>
			</div>

			<div class="mb120">
				<img :src="require(`@/assets/img_new/about/command.jpg`)" alt="" class="mb60-40 animationBlockFadeUp">
				<p class="color-gray animationBlockFadeUp fs20-2">Закрываем полный цикл разработки: бизнес-аналитика, проектирование интерфейсов, дизайн, разработка web & mobile любой сложности и дальнейшая техническая поддержка проекта.</p>
			</div>

			<div class="mb150-90-60">
				<div class="h3 mainTitle mb80 color-gray animationBlockFadeUp">Работаем с <b class="color-black">финансовым сектором, страховыми компаниями, застройщиками,  производителями</b> и <b class="color-black">ритейлом</b>.</div>

				<ClientsWithReview :content="clientsWithReview"></ClientsWithReview>
			</div>
		</div>

		<div class="mb120">
			<p class="h2 mb60 container animationBlockFadeUp">Наши<br> достижения</p>
			<Awards :content="awards"></Awards>
		</div>

		<div class="mb120 container">
			<Employees :content="employees"></Employees>
		</div>

		<div class="blackBack pt120-90-60 pb120-90-60 whiteText">
			<div class="container">
				<div class="inlineBlock">
					<div class="inlineBlock__left">
						<p class="h2 animationBlockFadeUp">Работа в компании</p>
					</div>
					<div class="inlineBlock__right">
						<p class="color-gray mb40-32 animationBlockFadeUp fs20-2">
							Ценим и развиваем каждого сотрудника. Ознакомьтесь со списком открытых вакансий.
						</p>
						<router-link to="/vacancies/" class="link white animationBlockFadeUp fs14">Стать частью команды</router-link>	
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import reviewsData from "@/assets/json/reviews.json";

	export default {
		metaInfo: {
			title: 'О веб-студии Артрокетс',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Информация о компании Артрокетс. Основная компетенция веб-студии – создание и дальнейшее развитие сайтов и интернет-магазинов.' },
				{ vmid: 'og:title', property: 'og:title', content: 'О веб-студии Артрокетс' },
				{ vmid: 'og:description', property: 'og:description', content: 'Информация о компании Артрокетс. Основная компетенция веб-студии – создание и дальнейшее развитие сайтов и интернет-магазинов.' },
			],
		},
		data: () => ({
			percents: [
				{
					num: "50+",
					desc: "уникальных проектов разработано «под ключ»"
				},{
					num: "20+",
					desc: "проектов на регулярной поддержке"
				},{
					num: "15",
					desc: "профильных специалистов в команде"
				},{
					num: "4",
					desc: "года стабильной работы на рынке"
				}
			],
			clientsWithReview: {
				items: [
					{
						logo: require("@/assets/img_new/companies/abd.png")
					},{
						logo: require("@/assets/img_new/companies/dymov.png")
					},{
						logo: require("@/assets/img_new/companies/kaplife.png")
					},{
						logo: require("@/assets/img_new/companies/rgs-life.png")
					},{
						logo: require("@/assets/img_new/companies/agrosila.png")
					},{
						logo: require("@/assets/img_new/companies/pascal.png")
					},{
						logo: require("@/assets/img_new/companies/centrserdce.png")
					},{
						logo: require("@/assets/img_new/companies/medar.png")
					},{
						logo: require("@/assets/img_new/companies/fin-trans.png")
					},{
						logo: require("@/assets/img_new/companies/sberfond.png")
					},{
						logo: require("@/assets/img_new/companies/savage.png")
					},{
						logo: require("@/assets/img_new/companies/mpkabel.png")
					},{
						logo: require("@/assets/img_new/companies/larisa.png")
					},{
						logo: require("@/assets/img_new/companies/technorosst.png")
					},{
						logo: require("@/assets/img_new/companies/rgs-med.png")
					}
				],
				reviews: reviewsData
				// reviews: [
				// 	{
				// 		logo: '<span class="icon-kaplife"></span>',
				// 		text: "Артрокетс оперативно предоставил нам недостающих специалистов для разработки внутреннего сервиса компании. Благодаря данному усилению, мы успешно завершили запланированные работы в срок.",
				// 		author: {
				// 			img: require(`@/assets/img_new/outstuff/2.jpg`),
				// 			name: "Наталья Шмелева",
				// 			description: "Директор диджитал проектов"
				// 		}
				// 	},{
				// 		logo: '<span class="icon-abd2"></span>',
				// 		text: "Сотрудничаем с другими студиями и клиентами, предоставляем команду разработчиков и тестировщиков, которая будет работать под вашим управлением.",
				// 		author: {
				// 			img: require(`@/assets/img_new/outstuff/1.jpg`),
				// 			name: "Максим Пранов",
				// 			description: "Начальник Управления цифровой трансформации Ак Барс Дом"
				// 		}
				// 	}
				// ]
			},
			awards: {
				type: 2,
				items: [
					{
						icon: '<span class="icon-pointer"></span>',
						title: 'в рейтинге разработчиков интернет-магазинов Казани 2020 в верхнем ценовом сегменте',
						desc: '1ое место среди'
					},{
						icon: '<span class="icon-bitrix"></span>',
						title: '1С-Битрикс с 2017 года',
						desc: 'Золотой партнер'
					},{
						icon: '<span class="icon-golden"></span>',
						title: 'премии “Золотой сайт банка или страховой компании” 2019 г',
						desc: 'Серебряный призер'
					},{
						icon: '<span class="icon-raek"></span>',
						title: 'члены кластера РАЭК',
						desc: 'C 2020 года'
					},{
						icon: '<span class="icon-pointer"></span>',
						title: 'среди ведущих разработчиков сайтов 2019 год г. Казань Рейтинг Рунета',
						desc: '5-е место'
					},{
						icon: '<span class="icon-pointer"></span>',
						title: 'среди ведущих разработчиков интернет-магазинов 2019 год г. Казань Рейтинг Рунета',
						desc: '3-место'
					},{
						icon: '<span class="icon-pointer"></span>',
						title: '2019 год г. Казань рейтинг CMSMagazine',
						desc: 'Топ-10 ведущих студий'
					}
				]
			},
			employees: {
				title: "Главное — это люди",
				desc: "Все специалисты в команде у нас в штате. Это обеспечивает стабильное качество услуг и соблюдение сроков.",
				items: [
					{
						img: require(`@/assets/img_new/command/1.jpg`),
						name: "Булат",
						description: "Директор"
					},{
						img: require(`@/assets/img_new/command/2.jpg`),
						name: "Айгуль",
						description: "Руководитель отдела SEO"
					},{
						img: require(`@/assets/img_new/command/3.jpg`),
						name: "Айрат",
						description: "Проект-менеджер"
					},{
						img: require(`@/assets/img_new/command/4.jpg`),
						name: "Султан",
						description: "Backend разработчик"
					},{
						img: require(`@/assets/img_new/command/5.jpg`),
						name: "Руслан",
						description: "Fullstack разработчик"
					},{
						img: require(`@/assets/img_new/command/6.jpg`),
						name: "Арсений",
						description: "Web дизайнер"
					},{
						img: require(`@/assets/img_new/command/7.jpg`),
						name: "Алексей",
						description: "Проект-менеджер"
					},{
						img: require(`@/assets/img_new/command/8.jpg`),
						name: "Игорь",
						description: "Frontend разработчик"
					},{
						img: require(`@/assets/img_new/command/9.jpg`),
						name: "Наиль",
						description: "Арт-директор"
					},{
						img: require(`@/assets/img_new/command/10.jpg`),
						name: "Анна",
						description: "Копирайтер"
					}
				]
			}
		}),
		name: 'about',
		components: {
			Feedback: () 			=> import('@/components/new/Forms/Feedback'),
			Percents: () 			=> import('@/components/new/Percents'),
			ClientsWithReview: () 	=> import('@/components/new/ClientsWithReview'),
			Awards: () 				=> import('@/components/new/Awards'),
			Employees: () 			=> import('@/components/new/Employees')
		}
	}
</script>
